<script>
  let mode = localStorage.getItem("colorScheme") || "system"; // Get initial mode from localStorage or default to 'system'

  // Function to set theme based on mode
  function setTheme() {
    const isDarkModePreferred = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches;
    document.documentElement.classList.toggle(
      "dark",
      mode === "dark" || (mode === "system" && isDarkModePreferred),
    );
    localStorage.setItem("colorScheme", mode);
  }

  // Set the initial theme on component mount
  setTheme();

  // Function to toggle dark mode
  function toggleMode() {
    mode = mode === "light" ? "dark" : mode === "dark" ? "system" : "light";
    setTheme(); // Update theme whenever mode changes
  }

  // Listen for changes in system color scheme
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (e) => {
      if (mode === "system") {
        setTheme(); // Update theme if mode is 'system'
      }
    });
</script>

<button
  class="toggle-dark mx-5 w-14 h-10 rounded-md font-semibold"
  on:click={toggleMode}
>
  {#if mode === "dark"}
    <!-- moon icon for dark mode -->
    <svg
      class="w-6 h-6 text-gray-800 dark:text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        d="M11.675 2.015a.998.998 0 0 0-.403.011C6.09 2.4 2 6.722 2 12c0 5.523 4.477 10 10 10 4.356 0 8.058-2.784 9.43-6.667a1 1 0 0 0-1.02-1.33c-.08.006-.105.005-.127.005h-.001l-.028-.002A5.227 5.227 0 0 0 20 14a8 8 0 0 1-8-8c0-.952.121-1.752.404-2.558a.996.996 0 0 0 .096-.428V3a1 1 0 0 0-.825-.985Z"
        clip-rule="evenodd"
      />
    </svg>
  {:else if mode === "light"}
    <!-- sun icon for light mode -->
    <svg
      class="w-6 h-6 text-gray-800 dark:text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        d="M13 3a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V3ZM6.343 4.929A1 1 0 0 0 4.93 6.343l1.414 1.414a1 1 0 0 0 1.414-1.414L6.343 4.929Zm12.728 1.414a1 1 0 0 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 1.414 1.414l1.414-1.414ZM12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-9 4a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H3Zm16 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2ZM7.757 17.657a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414Zm9.9-1.414a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM13 19a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Z"
        clip-rule="evenodd"
      />
    </svg>
  {:else}
    <!-- system icon for system mode -->
    <svg
      class="w-6 h-6 dark:block hidden"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <!-- White border -->
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="none"
        stroke="white"
        stroke-width="4"
      />
      <!-- Left half (black) -->
      <path d="M12,1 A11,11 0 0,1 12,23 L12,12 Z" fill="black" />
      <!-- Right half (white) -->
      <path d="M12,1 A11,11 0 0,0 12,23 L12,12 Z" fill="white" />
    </svg>

    <svg
      class="w-6 h-6 block dark:hidden"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <!-- White border -->
      <circle
        cx="12"
        cy="12"
        r="10"
        fill="none"
        stroke="black"
        stroke-width="4"
      />
      <!-- Left half (black) -->
      <path d="M12,1 A11,11 0 0,1 12,23 L12,12 Z" fill="black" />
      <!-- Right half (white) -->
      <path d="M12,1 A11,11 0 0,0 12,23 L12,12 Z" fill="white" />
    </svg>
  {/if}
</button>
